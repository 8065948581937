import PasswordValidator from 'password-validator';

const validator = new PasswordValidator()
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .lowercase()
  .has()
  .uppercase();

const errorMessage =
  'Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter.';

const validatePassword = password => (validator.validate(password) ? false : errorMessage);

export default validatePassword;
