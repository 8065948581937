// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import isProduction from 'lib/isProduction';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://9268001eb08d47a9a5834d199523cbf9@o466734.ingest.sentry.io/6250737',
  tracesSampleRate: isProduction ? 0.2 : 1, // https://develop.sentry.dev/sdk/performance/#tracessamplerate
  enabled: ['production', 'preview'].includes(process.env.VERCEL_ENV),
  environment: isProduction ? 'production' : 'preview',
  denyUrls: [
    // Browser extensions
    /^safari-extension:\/\//i,
    /^safari-web-extension:\/\//i,
    /^chrome-extension:\/\//i,
    // CookiePro
    /cookiepro.com/i,
  ],
});
