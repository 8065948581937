const batchRequest =
  fetcher =>
  async ({ queryBatch, pluckData, totalNumberOfItems, locale }) => {
    const iterableArray = Array.from(Array(Math.ceil(totalNumberOfItems / 50)).keys());
    const itemsMixed = Promise.all(
      iterableArray.map(async (n, i) => {
        const tempItems = await fetcher(queryBatch({ skip: n * 50, locale }));
        if (pluckData(tempItems))
          return {
            index: i,
            items: pluckData(tempItems),
          };
        return null;
      })
    );
    return (await itemsMixed)
      .sort((a, b) => a.index - b.index)
      .flatMap(itm => itm?.items)
      .filter(itm => itm);
  };

export default batchRequest;
