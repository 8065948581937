import useSWR from 'swr/immutable';
import { get, keys, set } from 'lib/utils/localStorage';
import { useStoreContext } from 'contexts';
import getCart from 'lib/shopify/getCart';
import addLineItems from 'lib/shopify/addLineItems';
import getCurrency from 'lib/storeLocales/getCurrency';
import updateLineItems from 'lib/shopify/updateLineItems';
import removeLineItems from 'lib/shopify/removeLineItems';
import { useState } from 'react';
import { parseMetafields } from 'lib/shopify/fetchCollectionProducts';

const getLineItemEdgesSubtotal = lineEdges =>
  lineEdges
    .map(({ node }) => node)
    .reduce((acc, curr) => acc + curr.merchandise.priceV2.amount * curr.quantity, 0);

const useCart = () => {
  const { store, currency, locale } = useStoreContext();

  const [isMutating, setIsMutating] = useState(false);
  const [cartGiftLoading, setCartGiftLoading] = useState(false);

  const { data: { hasAutomaticDiscount } = { hasAutomaticDiscount: false } } = useSWR(
    `/api/shopify/checkout/has-automatic-discount?store=${store}`,
    key => fetch(key).then(r => r.json())
  );

  const {
    data: cart,
    error,
    mutate,
  } = useSWR(
    ['cart', store],
    async (_, checkoutStore) => getCart(checkoutStore)(get(keys.cart(checkoutStore))),
    {
      onSuccess: async newCart => {
        if (newCart) {
          set(keys.cart(store), newCart.data.id);
        }
      },
    }
  );

  const add = async merchandiseId => {
    if (isMutating) return;
    setIsMutating(true);

    await mutate(addLineItems(currency)(cart.data.id, [{ merchandiseId, quantity: 1 }]), {
      revalidate: false,
    });

    setIsMutating(false);
  };

  const update = async (lineId, quantity) => {
    if (isMutating) return;

    setIsMutating(true);

    // Optimistic data based on variant (to account for discount line items)
    const lineItemVariantId = cart.data.lines.edges.find(({ node: { id } }) => id === lineId).node
      .merchandise.id;
    const lineEdges = cart.data.lines.edges.reduce((acc, curr) => {
      const isVariant = curr.node.merchandise.id === lineItemVariantId;
      const variant = acc.find(
        ({
          node: {
            merchandise: { id },
          },
        }) => id === lineItemVariantId
      );

      if (isVariant)
        return !variant
          ? [
              ...acc,
              {
                node: {
                  ...curr.node,
                  quantity:
                    // Add quantity of other variant lineItem
                    (cart.data.lines.edges
                      .filter(
                        ({
                          node: {
                            id,
                            merchandise: { id: merchandiseId },
                          },
                        }) => merchandiseId === lineItemVariantId && id !== curr.node.id
                      )
                      ?.reduce((qAcc, qCurr) => qAcc + qCurr.node.quantity, 0) || 0) + quantity,
                },
              },
            ]
          : acc;

      return [...acc, curr];
    }, []);

    await mutate(
      updateLineItems(store)(
        cart.data.id,
        cart.data.lines.edges
          .map(({ node }) => node)
          .filter(l => l.id === lineId)
          .map(({ id, merchandise: { id: merchandiseId }, attributes }) => ({
            id,
            merchandiseId,
            attributes,
            quantity,
          }))
      ),
      {
        optimisticData: {
          data: {
            ...cart.data,
            lines: {
              edges: lineEdges,
            },
            cost: {
              subtotalAmount: {
                amount: getLineItemEdgesSubtotal(lineEdges),
                currencyCode: cart.data.cost.subtotalAmount.currencyCode,
              },
            },
          },
        },
        revalidate: false,
      }
    );

    setIsMutating(false);
  };

  const remove = async lineId => {
    if (isMutating) return;

    setIsMutating(true);

    // Optimistic data based on variant (to account for discount line items)
    const lineItemMerchandiseId = cart.data.lines.edges.find(({ node: { id } }) => id === lineId)
      .node.merchandise.id;
    const lineEdges = cart.data.lines.edges.filter(
      ({
        node: {
          merchandise: { id },
        },
      }) => id !== lineItemMerchandiseId
    );
    await mutate(removeLineItems(getCurrency(store))(cart.data.id, [lineId]), {
      optimisticData: {
        data: {
          ...cart.data,
          cost: {
            subtotalAmount: {
              amount: getLineItemEdgesSubtotal(lineEdges),
              currencyCode: cart.data.cost.subtotalAmount.currencyCode,
            },
          },
          lines: {
            edges: lineEdges,
          },
        },
      },
      revalidate: false,
    });

    setIsMutating(false);
  };

  const getCartGifts = async lines => {
    setCartGiftLoading(true);
    const giftLineItemIds = cart?.data?.lines?.edges
      .filter(l => l.node.attributes.find(({ key }) => key === '_discount'))
      .map(l => l.node.id);

    // Dont validate if no enabled automatic discounts
    if (!hasAutomaticDiscount && giftLineItemIds?.length === 0) return null;

    const [{ lines: newGiftLineItems }] = await Promise.all([
      // Fetch eligible gifts
      fetch(`/api/shopify/checkout/get-gift-line-items`, {
        method: 'POST',
        body: JSON.stringify({
          lines: cart?.data?.lines,
        }),
        headers: { store, 'Content-Type': 'application/json' },
      }).then(r => r.json()),
      // Remove all gifts if any
      ...(giftLineItemIds?.length
        ? [removeLineItems(currency)(cart.data.id, giftLineItemIds)]
        : []),
    ]);

    const filteredNewGiftLineItems = newGiftLineItems.filter(
      r => !lines.map(item => item.merchandise.id).includes(r.merchandise.id)
    );

    setCartGiftLoading(false);

    return { newGiftLineItems, filteredNewGiftLineItems };
  };

  const lines = (cart?.data?.lines?.edges?.map(({ node }) => node) || [])
    .reduce((acc, curr) => {
      // Merging lineItems per variant
      // For some reason Shopify creates new lineItems if an automatic discount is applied
      const existingMerchandiseLineItem = acc.find(
        l => l.merchandise.id === curr.merchandise.id || l.id.includes('_gift')
      );

      if (existingMerchandiseLineItem)
        return [
          ...acc.filter(l => l.id !== existingMerchandiseLineItem.id),
          {
            ...(existingMerchandiseLineItem.quantity > curr.quantity
              ? existingMerchandiseLineItem
              : curr),
            merchandise: {
              ...existingMerchandiseLineItem.merchandise,
            },
            merchandiseQuantity: existingMerchandiseLineItem.quantity + curr.quantity,
          },
        ];

      return [...acc, curr];
    }, [])
    .map(l => ({
      ...l,
      merchandise: {
        ...l.merchandise,
        product: parseMetafields(l.merchandise.product),
      },
    }));

  return {
    add,
    update,
    remove,
    checkout: cart,
    getCartGifts,
    cartGiftLoading,
    setCartGiftLoading,
    isLoading: typeof cart === 'undefined' || isMutating,
    cart: cart
      ? {
          ...cart?.data,
          // Sort non-discount items first
          lineItems: [
            ...lines.filter(l => !l.attributes.find(a => a.key === '_discount')),
            ...lines.filter(l => l.attributes.find(a => a.key === '_discount')),
          ],
          webUrl: `${cart?.data?.checkoutUrl}&locale=${locale}`,
        }
      : null,
    error,
    isEmpty: lines.length === 0,
    count: lines.reduce(
      (acc, { quantity, merchandiseQuantity = quantity }) => acc + merchandiseQuantity,
      0
    ),
  };
};

export default useCart;
