const config = require('../../store-locales.config.json');

/**
 * Export compatible with import & require
 */
Object.defineProperty(exports, '__esModule', {
  value: true,
});

exports.default = config;
