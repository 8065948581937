export const keys = {
  cart: store => `cart::${store}`,
  checkout: store => `checkout::${store}`,
  wishlist: store => `wishlistProductIds::${store}`,
  showedNewsletter: `showed_newsletter`,
};

const isBrowser = () => typeof window !== 'undefined';

export const set = (key, value) => {
  if (isBrowser()) window.localStorage.setItem(key, value);
};

export const remove = key => {
  if (isBrowser()) window.localStorage.removeItem(key);
};

export const get = key => {
  if (!isBrowser()) return null;

  return window.localStorage.getItem(key) || null;
};
