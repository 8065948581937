import StoreEnum from 'lib/storeLocales/enums/StoreEnum';

type Key =
  | 'SHOPIFY_ADMIN_ACCESS_TOKEN'
  | 'NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN'
  | 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN'
  | 'NEXT_PUBLIC_SHOPIFY_STORE_MULTIPASS'
  | 'NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID'
  | 'NEXT_PUBLIC_KLAVIYO_KEY_PUBLIC'
  | 'NEXT_PUBLIC_ALGOLIA_INDEX'
  | 'KLAVIYO_PRIVATE_API_KEY'
  | 'SWYM_PID'
  | 'SWYM_API_KEY';

// TODO: Find better solution, can't dynamically access process.env
// https://nextjs.org/docs/api-reference/next.config.js/environment-variables
const variables = {
  SHOPIFY_ADMIN_ACCESS_TOKEN_EU: process.env.SHOPIFY_ADMIN_ACCESS_TOKEN_EU,
  SHOPIFY_ADMIN_ACCESS_TOKEN_UK: process.env.SHOPIFY_ADMIN_ACCESS_TOKEN_UK,
  SHOPIFY_ADMIN_ACCESS_TOKEN_US: process.env.SHOPIFY_ADMIN_ACCESS_TOKEN_US,
  NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EU: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EU,
  NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_UK: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_UK,
  NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_US: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_US,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_EU:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_EU,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_UK:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_UK,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_US:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_US,
  NEXT_PUBLIC_SHOPIFY_STORE_MULTIPASS_EU: process.env.NEXT_PUBLIC_SHOPIFY_STORE_MULTIPASS_EU,
  NEXT_PUBLIC_SHOPIFY_STORE_MULTIPASS_UK: process.env.NEXT_PUBLIC_SHOPIFY_STORE_MULTIPASS_UK,
  NEXT_PUBLIC_SHOPIFY_STORE_MULTIPASS_US: process.env.NEXT_PUBLIC_SHOPIFY_STORE_MULTIPASS_US,
  NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID_EU: process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID_EU,
  NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID_UK: process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID_UK,
  NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID_US: process.env.NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID_US,
  NEXT_PUBLIC_KLAVIYO_KEY_PUBLIC_EU: process.env.NEXT_PUBLIC_KLAVIYO_KEY_PUBLIC_EU,
  NEXT_PUBLIC_KLAVIYO_KEY_PUBLIC_UK: process.env.NEXT_PUBLIC_KLAVIYO_KEY_PUBLIC_UK,
  NEXT_PUBLIC_KLAVIYO_KEY_PUBLIC_US: process.env.NEXT_PUBLIC_KLAVIYO_KEY_PUBLIC_US,
  NEXT_PUBLIC_ALGOLIA_INDEX_EU: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_EU,
  NEXT_PUBLIC_ALGOLIA_INDEX_UK: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_UK,
  NEXT_PUBLIC_ALGOLIA_INDEX_US: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_US,
  KLAVIYO_PRIVATE_API_KEY_EU: process.env.KLAVIYO_PRIVATE_API_KEY_EU,
  KLAVIYO_PRIVATE_API_KEY_UK: process.env.KLAVIYO_PRIVATE_API_KEY_UK,
  KLAVIYO_PRIVATE_API_KEY_US: process.env.KLAVIYO_PRIVATE_API_KEY_US,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN_EU:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN_EU,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN_UK:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN_UK,
  NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN_US:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN_US,
  SWYM_PID_EU: process.env.SWYM_PID_EU,
  SWYM_PID_US: process.env.SWYM_PID_US,
  SWYM_PID_UK: process.env.SWYM_PID_UK,
  SWYM_API_KEY_EU: process.env.SWYM_API_KEY_EU,
  SWYM_API_KEY_US: process.env.SWYM_API_KEY_US,
  SWYM_API_KEY_UK: process.env.SWYM_API_KEY_UK,
};

const getStoreVariable = (store: StoreEnum, key: Key): string => {
  const storeKey = store.toUpperCase();
  const value = variables[`${key}_${storeKey}`];
  if (value === undefined) throw new Error(`Variable [${key}] not found for store [${store}]`);
  return value;
};

export default getStoreVariable;
