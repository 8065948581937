import { getShopifyIdKey } from 'lib/shopify/getByCurrency';
import getStoreByCurrency from 'lib/storeLocales/getStoreByCurrency';
import stripShopifyGid from 'lib/utils/stripShopifyGid';
import { preparePayload } from '.';
import retryFetch from './retryFetch';
import { parseMetafields } from './fetchCollectionProducts';
import { BASE_PRODUCT } from './fragments';

export const PRODUCTS_BY_ID_QUERY = `
  query multipleProducts($ids: [ID!]!){
    nodes(ids: $ids){
      ... on Product {
        ${BASE_PRODUCT}
        compareAtPriceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        
        variants(first: 250) {
          edges {
            node {
              id
              priceV2 {
                amount
                currencyCode
              }
              compareAtPriceV2 {
                amount
                currencyCode
              }
              quantityAvailable
              selectedOptions {
                name
                value
              }
            }
          }
        }
        options(first: 3) {
          id
          name
          values
        }
      }
    }
  }
`;

export const getMultipleProducts = async (store, productsIds) => {
  const {
    data: { nodes: products },
  } = await retryFetch(store, preparePayload(PRODUCTS_BY_ID_QUERY, { ids: productsIds }));
  return products.map(parseMetafields);
};

const findShopifyProductById = (shopifyProducts, shopifyId) =>
  shopifyProducts.find(product => product.id === `gid://shopify/Product/${shopifyId}`);

export const findContentfulProductsInShopify = async (productsCollectionItems, currency) => {
  const shopifyIdKey = getShopifyIdKey(currency);

  const productsIds = productsCollectionItems
    .filter(Boolean)
    .filter(item => item[shopifyIdKey])
    .map(item => `gid://shopify/Product/${stripShopifyGid(item[shopifyIdKey])}`);

  if (!productsIds.length) return [];

  const shopifyProducts = await getMultipleProducts(getStoreByCurrency(currency), productsIds);

  return productsCollectionItems
    .filter(item => findShopifyProductById(shopifyProducts, item[shopifyIdKey])) // Filtering product that were found in shopify
    .map(item => ({
      ...item,
      ...findShopifyProductById(shopifyProducts, item[shopifyIdKey]),
    }));
};
