import { useState } from 'react';
import { useCustomer } from 'hooks';
import fetchJson from 'lib/utils/fetchJson';
import { useStoreContext } from 'contexts';

export const useForgotPasswordCustomer = () => {
  const { currency } = useStoreContext();

  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  const { mutateCustomer } = useCustomer();

  const forgotPassword = async email => {
    setState({ response: null, loading: true, error: null });
    try {
      await mutateCustomer(
        fetchJson('/api/shopify/account/forgot-password', {
          method: 'POST',
          headers: { currency },
          body: JSON.stringify({
            email,
          }),
        })
      );
      setState({
        response: true,
        loading: false,
        error: null,
      });
    } catch (error) {
      setState({
        response: null,
        loading: false,
        error: error?.message || true,
      });
    }
  };

  return [forgotPassword, state];
};

export default useForgotPasswordCustomer;
