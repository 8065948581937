import { headers } from '.';
import { CART_QUERY } from './fragments';
import { getStorefrontApiDomain } from './getByCurrency';

const addLineItems = currency => (cartId, lines) =>
  fetch(
    `https://${getStorefrontApiDomain(currency)}/api/${
      process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION
    }/graphql`,
    {
      method: 'POST',
      headers: headers(currency),
      body: JSON.stringify({
        query: `
          mutation cartLinesAdd($lines: [CartLineInput!]!,  $cartId: ID!) {
            cartLinesAdd(cartId: $cartId, lines: $lines) {
              cart {
                ${CART_QUERY}
              }
              userErrors {
                code
                field
                message
              }
            }
          }
        `,
        variables: {
          cartId,
          lines,
        },
      }),
    }
  )
    .then(r => r.json())
    .then(r => ({ data: r.data.cartLinesAdd.cart }));

export default addLineItems;
