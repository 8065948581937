import getCurrency from 'lib/storeLocales/getCurrency';
import { headers } from '.';
import { CART_QUERY } from './fragments';
import { getStorefrontApiDomain } from './getByCurrency';

const updateLineItems = store => async (cartId, lines) => {
  const currency = getCurrency(store);
  const SHOPIFY_STORE_DOMAIN = getStorefrontApiDomain(currency);

  return fetch(
    `https://${SHOPIFY_STORE_DOMAIN}/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql`,
    {
      method: 'POST',
      headers: headers(currency),
      body: JSON.stringify({
        query: `mutation cartLinesUpdate($lines: [CartLineUpdateInput!]!, $cartId: ID!) {
            cartLinesUpdate(lines: $lines, cartId: $cartId) {
                cart {
                  ${CART_QUERY}
                }
                userErrors {
                  code
                  field
                  message
                }
              }
            }
          `,
        variables: {
          cartId,
          lines,
        },
      }),
    }
  )
    .then(r => r.json())
    .then(r => ({ data: r.data.cartLinesUpdate.cart }));
};

export default updateLineItems;
