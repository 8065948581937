import { useState } from 'react';
import { useCustomer, useRouterRefresh } from 'hooks';
import fetchJson from 'lib/utils/fetchJson';
import { useStoreContext } from 'contexts';

export const useCustomerAddress = customerAccessToken => {
  const { currency } = useStoreContext();
  const refresh = useRouterRefresh();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);

  const resetState = () => {
    setLoading(false);
    setError();
    setResponse(false);
  };

  const { mutateCustomer } = useCustomer();

  const dispatch = async (action, payload) => {
    setError();
    setResponse(false);
    setLoading(true);

    try {
      await mutateCustomer(
        fetchJson('/api/shopify/account/update-address', {
          method: 'POST',
          headers: { currency },
          body: JSON.stringify({
            customerAccessToken,
            ...payload,
            action,
          }),
        })
      );
    } catch (e) {
      setError(e.message || true);
    }

    setLoading(false);
    setResponse(true);
    refresh(); // refresh server side props as the account pages are ssr
  };

  const createAddress = async address => dispatch('CREATE', { address });
  // const updateDefaultAddress = async id => dispatch('DEFAULT_UPDATE', { id });
  const deleteAddress = async id => dispatch('DELETE', { id });
  const updateAddress = async (id, address) => dispatch('UPDATE', { id, address });

  return [
    {
      createAddress,
      // updateDefaultAddress,
      deleteAddress,
      updateAddress,
      resetState,
    },
    { response, loading, error },
  ];
};

export default useCustomerAddress;
