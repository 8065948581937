import { headers } from '.';
import { CART_QUERY } from './fragments';
import { getStorefrontApiDomain } from './getByCurrency';

const removeLineItems = currency => (cartId, lineIds) =>
  fetch(
    `https://${getStorefrontApiDomain(currency)}/api/${
      process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION
    }/graphql`,
    {
      method: 'POST',
      headers: headers(currency),
      body: JSON.stringify({
        query: `
        mutation cartLinesRemove($cartId: ID!, $lines: [ID!]!) {
          cartLinesRemove(cartId: $cartId, lineIds: $lines){
            cart {
              ${CART_QUERY}
            }
            userErrors {
              code
              field
              message
            }
          }
      }
    `,
        variables: {
          cartId,
          lines: lineIds,
        },
      }),
    }
  )
    .then(r => r.json())
    .then(r => ({ data: r.data.cartLinesRemove.cart }));

export default removeLineItems;
