import { LINK_QUERY_FIELDS } from 'lib/contentful/fragments';
import { SiteLink } from 'components/shared';
import { getLinkHref } from 'lib/utils/helpers';
import { useSpring, animated } from 'react-spring';
import springConfigs from 'lib/utils/springConfigs';
import { fetchContentPayload } from 'lib/contentful/api';

export const USP_BANNER_HEIGHT = '38px';

export const getUspBanner = async ({ locale = 'en', preview = false, store = 'eu' }) =>
  fetchContentPayload(
    {
      query: ` 
   query uspBanner($locale: String!, $preview: Boolean!) {
     
       globalModuleCollection(
         locale: $locale,
         limit: 1
         where: { name: "USP Banner" }
         preview: $preview
       ) {
         items {
           resourcesCollection {
             items {
               ... on ComponentBanner {
                 name
                 __typename
                 sys {
                   id
                 }
                 title
                 link {
                   ${LINK_QUERY_FIELDS}
                 }
               }
             }
           }
         }
       }
     
   }
 `,
      variables: {
        locale,
        preview,
      },
    },
    preview
  ).then(
    r =>
      (r.globalModuleCollection?.items[0]?.resourcesCollection?.items || [])
        .filter(Boolean)
        .find(({ name }) => name.toLowerCase().startsWith(`usp banner ${store.toLowerCase()}`)) ||
      null
  );

const UspBanner = ({ data: componentBanner }) => {
  const textSpring = useSpring({
    from: { transform: 'translateY(-100%)', opacity: '0' },
    to: { transform: 'translateY(0%)', opacity: '1' },
    delay: 1000,
    config: springConfigs.easeInButBetter,
  });

  return componentBanner ? (
    <div
      style={{
        height: USP_BANNER_HEIGHT,
      }}
      className="fixed left-0 top-0 z-[10000] flex w-full items-center justify-center bg-blue p-[10px]"
    >
      <animated.p
        style={{ ...textSpring }}
        className="text-center font-nimbus-sans-extd-d text-[10px] uppercase leading-[1] text-white md:gap-x-[20px] md:text-[12px]"
      >
        {componentBanner.title}
        {componentBanner.link && (
          <SiteLink
            className="ml-2 inline underline lg:ml-4"
            href={getLinkHref(componentBanner.link.link)}
            external={componentBanner.link.externalLink}
          >
            {componentBanner.link.text}
          </SiteLink>
        )}
      </animated.p>
    </div>
  ) : null;
};

export default UspBanner;
