import { useState } from 'react';
import { getMultipleProducts } from 'lib/shopify/getMultipleProducts';
import { useStoreContext } from 'contexts';
import getStoreByCurrency from 'lib/storeLocales/getStoreByCurrency';

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function useFetchMultipleProducts() {
  const { currency } = useStoreContext();
  const [state, setState] = useState([]);
  const [cachedIdsArray, setCachedIdsArray] = useState([]);
  async function fetchMultipleProducts(ids) {
    if (JSON.stringify(cachedIdsArray) !== JSON.stringify(ids)) {
      setState([]);
    }
    setCachedIdsArray(ids);
    // ids need to be storefront product id
    const decodedIds = ids?.map(id => typeof window !== 'undefined' && window.atob(id));

    if (decodedIds?.find(id => !id?.includes('gid://shopify/Product/'))) {
      throw new Error(`One or more of your ids are provided in an incorrect format`);
    }

    const idsChunks = sliceIntoChunks(ids, 250);

    return idsChunks?.forEach(async chunk => {
      const res = await getMultipleProducts(getStoreByCurrency(currency), chunk);

      if (res.length > 0) {
        setState(prevState => (prevState?.length > 0 ? [...prevState, ...res] : res));
      }
    });
  }

  return [state, fetchMultipleProducts];
}

export default useFetchMultipleProducts;
