import fetchRetry from '@vercel/fetch-retry';
import fetch from 'node-fetch';

const options = {
  // 15ms, 45, 135, 405, 1215...
  minTimeout: 15,
  retries: 15,
  factor: 3,
  maxRetryAfter: 20,
};

const retryFetch = fetchRetry(fetch, options);

export default retryFetch;
