import { CART_QUERY } from 'lib/shopify/fragments';
import getStoreVariable from 'lib/storeLocales/getStoreVariable';
import { preparePayload } from 'lib/shopify';
import { getConsentCookie } from 'lib/utils/getConsentCookie';

const isExpired = cart => {
  const createdAt = new Date(cart.createdAt);
  const now = new Date();
  const differenceInDays = (now.getTime() - createdAt.getTime()) / (1000 * 3600 * 24);
  return differenceInDays > 85;
};

const fetchCart = store => async id => {
  if (!id) return null;

  const domain = getStoreVariable(store, 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN');
  const token = getStoreVariable(store, 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN');

  const query = `query($id: ID!) {
    cart(id: $id) {
      id
      ... on Cart {
        ${CART_QUERY}
      }
    }
  }`;

  const variables = { id };

  const json = await fetch(
    `https://${domain}/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Shopify-Storefront-Access-Token': token,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    }
  ).then(r => r.json());

  if (json.errors) {
    throw new Error(
      `Could not get cart!\n\n${json.errors.map(({ message }) => message).join('\n')}`
    );
  }

  const {
    data: { cart },
  } = json;

  return cart;
};

const fetchCreateCart = async store => {
  const domain = getStoreVariable(store, 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN');
  const token = getStoreVariable(store, 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN');

  const query = `
  mutation cartCreate {
    cartCreate (input: {}) {
      cart {
        ${CART_QUERY}
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  `;

  const consentCookie = getConsentCookie();

  const payload = preparePayload(query, {
    input: {
      attributes: [
        {
          key: 'COOKIE_PRO_OPTANON_CONSENT',
          value: consentCookie,
        },
      ],
    },
  });

  const json = await fetch(
    `https://${domain}/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Shopify-Storefront-Access-Token': token,
      },
      body: JSON.stringify(payload),
    }
  ).then(r => r.json());

  if (json.errors) {
    throw new Error(
      `Could not create cart!\n\n${json.errors.map(({ message }) => message).join('\n')}`
    );
  }

  const {
    data: {
      cartCreate: { cart },
    },
  } = json;

  return cart;
};

const getCart = store => async id => {
  const cart = await fetchCart(store)(id);

  if (!cart || cart.completedAt || isExpired(cart)) return { data: await fetchCreateCart(store) };

  return { data: cart };
};

export default getCart;
