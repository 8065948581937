import getStoreByCurrency from 'lib/storeLocales/getStoreByCurrency';
import getStoreVariable from 'lib/storeLocales/getStoreVariable';

export const headers = currency => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Shopify-Storefront-Access-Token': getStoreVariable(
    getStoreByCurrency(currency),
    'NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN'
  ),
});

export const adminHeadersByStore = store => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Shopify-Access-Token': getStoreVariable(store, 'SHOPIFY_ADMIN_ACCESS_TOKEN'),
});

export const adminHeaders = currency => adminHeadersByStore(getStoreByCurrency(currency));

export const preparePayload = (query, variables) => ({
  query,
  variables,
});
