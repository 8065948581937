const DenhamLogo = ({ className }) => (
  <svg
    className={className || ''}
    viewBox="0 0 132 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.3357 17.7325H0V0H4.3357C11.498 0 15.8089 3.64917 15.8089 8.86314C15.8151 14.0833 11.498 17.7325 4.3357 17.7325ZM5.1645 4.05119H4.55218V13.6875H5.1645C8.30649 13.6875 11.2073 12.4628 11.2073 8.86933C11.2073 5.27583 8.30649 4.05119 5.1645 4.05119Z"
      fill="currentColor"
    />
    <path
      d="M22.0059 17.7325V0H35.2913V3.93986H26.558V6.63035H34.8089V10.5702H26.558V13.7926H35.2913V17.7325H22.0059Z"
      fill="currentColor"
    />
    <path
      d="M55.1077 17.7325L47.0672 6.9829V17.7325H42.6758V0H47.0672L55.1077 10.731V0H59.4743V17.7325H55.1077Z"
      fill="currentColor"
    />
    <path
      d="M78.6181 17.7325V10.8856H71.4311V17.7263H66.8789V0H71.4311V6.8159H78.6181V0H83.1703V17.7325H78.6181Z"
      fill="currentColor"
    />
    <path
      d="M102.015 17.7325L100.821 14.4049H94.8032L93.5785 17.7325H89.082L95.6505 0H99.9615L106.511 17.7325H102.015ZM97.8091 4.5027L95.867 10.9661H99.7265L97.8091 4.5027Z"
      fill="currentColor"
    />
    <path
      d="M127.565 17.7325V7.91065L123.57 14.2132H121.152L117.187 7.91065V17.7325H112.82V0H117.131L122.376 8.52297L127.621 0H131.932V17.7325H127.565Z"
      fill="currentColor"
    />
  </svg>
);

export default DenhamLogo;
