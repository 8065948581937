/* eslint-disable no-restricted-syntax */
import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import Script from 'next/script';
import { useEffect, useState } from 'react';

const HighstreetAppBanner = () => {
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     const highstreet = document.getElementById('highstreet-smart-app-banner');

  //     if (highstreet && window.highstreetBanner.isShown()) {
  //       window.sessionStorage.setItem('highstreet', true);
  //       trackInWindow({
  //         event: trackEvents.viewDownloadApp,
  //         page_path: window.location.pathname,
  //       });
  //     }
  //   }, 5000);

  //   return () => clearTimeout(timeout);
  // }, []);

  const [load, setLoad] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoad(true);
    }, 3000);

    return () => {
      setLoad(false);
      clearTimeout(timeOut);
    };
  }, []);

  return (
    load && (
      <Script
        id="highstreet"
        strategy="lazyOnload"
        src="https://denham.api.highstreetapp.com/smartbanner/eyJzdG9yZSI6ImRlbmhhbSIsImhvc3QiOiJkZW5oYW10aGVqZWFubWFrZXIuY29tIiwibG9jYWxlIjoiZW5fTkwiLCJtb2R1bGUiOiJob21lIiwiY29udHJvbGxlciI6ImhpZ2hzdHJlZXQifQ~~"
      />
    )
  );
};

export default HighstreetAppBanner;
