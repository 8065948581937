import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import Cookies from 'js-cookie';

const serverExperiments: {
  name: string;
  /**
   * @returns  the prefix of the path that should be rewritten
   */
  matcher: (path: string) => string | null;
  variants: number;
}[] = [];

export const getCookieName = name => `server_experiment_${name}`;

export const trackExperiment = () => {
  const experiment = serverExperiments.find(({ matcher }) => matcher(window.location.pathname));
  const variant = Cookies.get(getCookieName(experiment?.name)) ?? 0;
  if (experiment)
    trackInWindow({
      event: trackEvents.serverExperiment,
      server_experiment: experiment.name,
      server_experiment_variant: variant,
    });
};

export default serverExperiments;
