export const PREFIX = '/account';

const SLUGS = {
  HOME: 'home',
  ADDRESSES: 'addresses',
  ORDER_HISTORY: 'order-history',
  DELETE: 'delete',
};

export default SLUGS;
