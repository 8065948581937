// Get OptanonConsent cookie
export const getConsentCookie = () => {
  let consentCookieData = '';
  const consentCookie = document.cookie.split(';').find(c => c.includes('OptanonConsent'));

  if (consentCookie && consentCookie.length > 0) {
    consentCookieData = consentCookie.trim();
  }
  return consentCookieData;
};

export default { getConsentCookie };
