import { useState, useEffect } from 'react';

export function useIsDesktop() {
  const [windowWidth, setWindowWidth] = useState(375);
  const isDesktop = windowWidth >= 1024;

  useEffect(() => {
    if (typeof window === 'undefined') return () => {};

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return isDesktop;
}

export default useIsDesktop;
