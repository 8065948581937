import { createContext, useContext } from 'react';

const StoreContext = createContext({
  store: 'eu',
  locale: 'en',
  currency: 'EUR',
  preview: false,
});

export const StoreContextProvider = ({ store, locale, currency, children, preview }) => (
  <StoreContext.Provider value={{ store, locale, currency, preview }}>
    {children}
  </StoreContext.Provider>
);

export const useStoreContext = () => {
  const context = useContext(StoreContext);
  if (context === undefined) throw new Error(`Must be used within a provider`);
  return context;
};
