import { useState } from 'react';
import { useCustomer, useRouterRefresh } from 'hooks';
import fetchJson from 'lib/utils/fetchJson';

export const useUpdateAccountInfo = currency => {
  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  const { mutateCustomer } = useCustomer();

  const update = useRouterRefresh();

  const updateAccountInfo = async (accessToken, customer) => {
    setState({ response: null, loading: true, error: null });

    try {
      await mutateCustomer(
        fetchJson('/api/shopify/account', {
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Content-Type': 'application/json',
            currency,
          },
          body: JSON.stringify({
            accessToken,
            customer,
          }),
        })
      );
      setState({
        response: true,
        loading: false,
        error: null,
      });
      update();
    } catch (error) {
      setState({
        response: null,
        loading: false,
        error: error.message || true,
      });
    }
  };

  return [updateAccountInfo, state];
};

export default useUpdateAccountInfo;
