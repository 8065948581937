import dynamic from 'next/dynamic';

export const Swiper = dynamic(() => import('./swiper'));
export const SiteLink = dynamic(() => import('./sitelink'));
export const Icon = dynamic(() => import('./icon'));
export const RichText = dynamic(() => import('./richtext'));
export const Image = dynamic(() => import('./image'));
export const SliderPanel = dynamic(() => import('./slider-panel'));
export const Parallax = dynamic(() => import('./parallax'));
export const MobileMenuButton = dynamic(() => import('./mobile-menu-button'));
export const MobileMenuAccordion = dynamic(() => import('./mobile-menu-accordion'));
export const ArticleAccordion = dynamic(() => import('./articleAccordion'));
export const VideoPlayer = dynamic(() => import('./video-player'));
export const NotifyMePopup = dynamic(() => import('./popups'));
export const SizeGuide = dynamic(() => import('./size-guide'));
export const SimpleAccordion = dynamic(() => import('./simple-accordion'));
export const PriceLabel = dynamic(() => import('./price-label'));
export const OptionalSiteLinkWrapper = dynamic(() => import('./optional-sitelink-wrapper'));
export const Select = dynamic(() => import('./select'));
export const OptionalWrapper = dynamic(() => import('./optional-wrapper'));
export const ProductCard = dynamic(() => import('./product'));
