const tryJsonResponse = async response => {
  try {
    return await response.json();
  } catch (error) {
    return undefined;
  }
};

export default async function fetcher(...args) {
  const response = await fetch(...args);

  const data = await tryJsonResponse(response);

  if (response.ok) return data;

  throw new Error(data?.message || '');
}
