import { trackInWindow } from 'hooks/tracking/track';
import { trackExperiment } from 'lib/server-experiments';
import Script from 'next/script';

const GoogleTagManager = () => (
  <Script
    id="google-tag-manager"
    src={`https://collector.denham.com/denham.js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`}
    strategy="afterInteractive"
    onLoad={() => {
      trackInWindow({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
        'gtm.uniqueEventId': 0,
      });

      window.hasGTMInit = true;

      trackExperiment();
    }}
  />
);
export default GoogleTagManager;
