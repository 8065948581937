import CurrencyEnum from 'lib/storeLocales/enums/CurrencyEnum';
import StoreEnum from 'lib/storeLocales/enums/StoreEnum';
import config from './config';

const getStoreByCurrency = (currency: CurrencyEnum = CurrencyEnum.EUR): StoreEnum =>
  StoreEnum[
    Object.entries(config.storeCurrencies)
      .find(([, c]) => c.toString().toLowerCase() === currency.toLowerCase())[0]
      .toUpperCase()
  ];

export default getStoreByCurrency;
