import fetchJson from 'lib/utils/fetchJson';
import { keys, get } from 'lib/utils/localStorage';
import { useStoreContext } from 'contexts';

const useUpdateCheckoutAttributes = () => {
  const { store, currency } = useStoreContext();

  async function updateAttributes({
    ga4AnonymousId,
    uaAnonymousId,
    consentCookie = '',
    checkoutData = {},
  }) {
    const cartId = get(keys.cart(store));

    if (!cartId) {
      console.error('No active checkout');
      return null;
    }

    return fetchJson('/api/shopify/checkout/update-checkout-attributes', {
      method: 'POST',
      headers: { currency },
      body: JSON.stringify({
        cartId,
        attributes: [
          {
            key: 'ga4AnonymousId',
            value: `${ga4AnonymousId}`,
          },
          {
            key: 'uaAnonymousId',
            value: `${uaAnonymousId}`,
          },
          {
            key: 'COOKIE_PRO_OPTANON_CONSENT',
            value: consentCookie,
          },
          {
            key: 'CHECKOUT_DATA',
            value: checkoutData,
          },
        ],
      }),
    });
  }

  return updateAttributes;
};

export default useUpdateCheckoutAttributes;
