import getStoreByCurrency from 'lib/storeLocales/getStoreByCurrency';
import getStoreVariable from 'lib/storeLocales/getStoreVariable';

export const getStoreToken = currency =>
  getStoreVariable(getStoreByCurrency(currency), 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN');

export const getStoreDomain = currency =>
  getStoreVariable(getStoreByCurrency(currency), 'NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN');

export const getStorefrontApiDomain = currency =>
  getStoreVariable(getStoreByCurrency(currency), 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN');

export const getShopifyIdKey = currency => {
  switch (currency) {
    case 'EUR':
      return 'shopifyIdEu';
    case 'GBP':
      return 'shopifyIdGb';
    case 'USD':
      return 'shopifyIdUs';
    default:
      throw new Error('getShopifyIdKey - No currency specified');
  }
};
