export default {
  sliderPDP: {
    tension: 380,
    friction: 30,
  },
  sizeWrapperPDP: {
    tension: 400,
    friction: 10,
    clamp: true,
    mass: 0.1,
  },
  accordionWrapper: {
    tension: 300,
    friction: 33,
  },
  accordionContent: {
    tension: 340,
    friction: 33,
  },
  easeInButBetter: {
    tension: 293,
    friction: 35,
    precision: 0.001,
    velocity: 0.006,
  },
  easeInButStickier: {
    tension: 327,
    friction: 40,
    precision: 0.001,
    velocity: 0.006,
  },
  easeInButSlower: {
    tension: 253,
    friction: 120,
    precision: 0.001,
    velocity: 0.006,
  },
  heavyClamped: {
    mass: 2.8,
    tension: 120,
    friction: 16,
    clamp: true,
  },
  fastClamped: {
    tension: 355,
    friction: 40,
    clamp: true,
    precision: 0.001,
    velocity: 0.033,
  },
  fast: {
    tension: 335,
    friction: 32,
    velocity: 0.033,
  },
};
