import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import Script from 'next/script';
import { useEffect, useState } from 'react';

// https://docs.gorgias.com/en-US/advanced-customization-new-chat-81792

const gorgiasLoadKey = 'load_gorgias';
const delay = 30 * 1000;

export const gorgiasSrc =
  'https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=12861';

const Gorgias = () => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem(gorgiasLoadKey)) {
      setLoad(true);
    } else {
      const id = setTimeout(() => setLoad(true), delay);
      return () => {
        clearTimeout(id);
      };
    }

    return () => {};
  }, []);

  return (
    load && (
      <Script
        id="gorgias-chat-widget-install-v2"
        strategy="lazyOnload"
        src={gorgiasSrc}
        onLoad={() => {
          const { GorgiasChat } = window;

          window.localStorage.setItem(gorgiasLoadKey, true);

          let isFirstMessage = true;

          GorgiasChat.init().then(async chat => {
            chat.hidePoweredBy(true);

            chat.on('message:sent', () => {
              if (isFirstMessage)
                trackInWindow({
                  event: trackEvents.startChat,
                  page_path: window.location.pathname,
                  page_title: document.title,
                });

              isFirstMessage = false;
            });
          });
        }}
      />
    )
  );
};

export default Gorgias;
