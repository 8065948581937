import isDevelopment from 'lib/isDevelopment';
import filterObjectByKeys from 'lib/utils/filterObjectByKeys';
import retryFetch from './retryFetch';
import contentfulPublishedTagFilter from './contentfulPublishedTagFilter';
import { LIST_PRODUCT } from './fragments';

const spreadMetafields = ['product_data_plp', 'product_data'];

export const parseMetafields = product => {
  const metafieldKeys = Object.keys(product).filter(k => k.startsWith('metafield'));
  const metafields = metafieldKeys
    .map(k => product[k])
    .filter(Boolean)
    .reduce(
      (acc, { key, value, type }) =>
        spreadMetafields.includes(key)
          ? {
              ...acc,
              ...JSON.parse(value),
            }
          : {
              ...acc,
              [key]: type === 'json' ? JSON.parse(value) : value,
            },
      {}
    );

  const productWithoutMetafields = filterObjectByKeys(
    product,
    Object.keys(product).filter(k => !metafieldKeys.includes(k))
  );

  return {
    comingSoon: product?.tags?.includes('collection-tag: coming-soon'),
    ...productWithoutMetafields,
    ...metafields,
  };
};

export const DEFAULT_SHOPIFY_SORT_KEY = 'COLLECTION_DEFAULT';

const recursiveFetchCollectionProducts =
  productsPerQuery => async (store, handle, filters, sortKey, reverse, limit, afterCursor) => {
    if (isDevelopment)
      console.log(
        `[recursiveFetchCollectionProducts] limit [${limit}] afterCursor [${afterCursor}]`
      );

    const query = `
  query collectionQuery($handle: String!, $filters: [ProductFilter!], $sortKey: ProductCollectionSortKeys, $reverse: Boolean, $first: Int, $after: String){
    collection(handle: $handle){
      products(first: $first, filters: $filters, sortKey: $sortKey, after: $after, reverse: $reverse) {
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            ${LIST_PRODUCT}
            metafield_product_data: metafield(namespace: "contentful", key: "product_data") {
              value
              key
              type
            }
            ${[
              'gender',
              'genericProductType',
              'style',
              'specialfeaturesv2',
              'genericColorName',
              'genericFit',
            ]
              .map(
                key =>
                  `metafield_f_${key}: metafield(namespace: "filters", key: "${key}") { value }`
              )
              .join(' ')}
          }
        }
      }
    }
  }
  `;

    const first = limit >= productsPerQuery ? productsPerQuery : limit;

    const {
      data: { collection },
    } = await retryFetch(store, {
      query,
      variables: {
        handle,
        filters,
        sortKey,
        reverse,
        first,
        after: afterCursor,
      },
    });

    if (!collection) return [];

    const {
      products: {
        pageInfo: { hasNextPage },
        edges,
      },
    } = collection;

    const products = edges.map(({ node }) => node);

    const remainingLimit = limit - first;

    if (remainingLimit > 0 && hasNextPage) {
      const { cursor: endCursor } = edges[edges.length - 1];
      const nextPageProducts = await recursiveFetchCollectionProducts(productsPerQuery)(
        store,
        handle,
        filters,
        sortKey,
        reverse,
        remainingLimit,
        endCursor
      );

      return [...products, ...nextPageProducts];
    }

    return products;
  };

const fetchCollectionProducts = async (
  store,
  handle,
  filters = [],
  sortKey = DEFAULT_SHOPIFY_SORT_KEY,
  reverse = false,
  limit = 2000,
  productsPerQuery = 200
) => {
  const timeKey = `[fetchCollectionProducts] store [${store}] handle [${handle}]`;
  if (isDevelopment) console.time(timeKey);
  const collectionProducts = await recursiveFetchCollectionProducts(productsPerQuery)(
    store,
    handle,
    filters,
    sortKey,
    reverse,
    limit
  );
  if (isDevelopment) console.timeEnd(timeKey);

  const products = collectionProducts
    .filter(contentfulPublishedTagFilter)
    .filter(({ metafield }) => metafield);

  return products.map(parseMetafields);
};

export default fetchCollectionProducts;
