import isBuilding from 'lib/isBuilding';

// use for all images except when there are separate mobile and desktop images
export const generateResponsiveImage = image => {
  if (image !== undefined) {
    return {
      baseUrl: image.url,
      contentType: image.contentType,
      title: image.title,
      width: image.width,
      height: image.height,
    };
  }
  return {
    title: image.title,
  };
};

export const artDirectingImage = (mobile, desktop) => {
  const imageMobile = mobile ? generateResponsiveImage(mobile) : '';
  const imageDesktop = desktop ? generateResponsiveImage(desktop) : '';

  const image = {
    mobile: imageMobile || '',
    desktop: imageDesktop || '',
  };

  return image;
};

export const formatPrice = (
  price,
  currency = 'EUR',
  maximumFractionDigits = 2,
  minimumFractionDigits = 0
) =>
  new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(price);

/**
 * formatPrice for Shopify price object
 */
export const formatShopifyPrice = ({ amount: price, currencyCode: currency }) =>
  formatPrice(price, currency);

export const formatDate = (date, locale = 'en') => {
  const dateLocales = {
    en: 'en-GB', // Default
    nl: 'nl-NL',
    de: 'de-DE',
  };

  const defaultDateLocale = dateLocales.en;
  const dateLocale = dateLocales[locale] || defaultDateLocale;
  return new Date(date).toLocaleDateString(dateLocale);
};

export const typesPaths = {
  PageGeneral: '/',
  PageProduct: '/item/',
  PageCollection: '/jeans/',
  PageProductsListing: '/collection/',
};

// eslint-disable-next-line no-underscore-dangle
export const getLinkHref = link => (link ? typesPaths[link.__typename] + link.slug : null);

export const delay = ms => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const camelize = str =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');

export const capitalize = str => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

// eslint-disable-next-line no-underscore-dangle
export const filterByType = typename => item => typename === item?.__typename;

export const filterByName =
  targetName =>
  ({ name }) =>
    targetName.trim() === name.trim();

export const createArticleGetterFromCollection = collection => name =>
  collection?.items.filter(filterByType('ComponentArticle')).filter(filterByName(name))[0];

export const pluckText = ({ text }) => text;

const pluckLinks = ({ linksCollection }) => linksCollection.items;

const pluckImage = ({ image }) => image;

const pluckImages = ({ imagesCollection }) => imagesCollection.items;

export const createMediaGetterFromCollection = collection => name =>
  collection?.items
    .filter(filterByType('ComponentMedia'))
    .filter(filterByName(name))
    .map(pluckImage)[0];

export const createMultipleMediaGetterFromCollection = collection => name =>
  collection?.items
    .filter(filterByType('ComponentMedia'))
    .filter(filterByName(name))
    .map(pluckImages)[0];

export const createMicroCopyGetterFromCollection = collection => name =>
  collection?.items
    .filter(filterByType('ComponentMicrocopy'))
    .filter(filterByName(name))
    .map(pluckText)[0];

export const createMultipleMicroCopyGetterFromCollection =
  collection =>
  (...args) =>
    args
      ?.flat()
      ?.map(
        name =>
          collection?.items
            .filter(filterByType('ComponentMicrocopy'))
            .filter(filterByName(name))
            .map(pluckText)[0]
      );

export const createNavGetterFromCollection = collection => name =>
  collection?.items
    .filter(filterByType('ComponentNav'))
    .filter(filterByName(name))
    .map(pluckLinks)[0];

export const pipe =
  (...fns) =>
  arg =>
    fns.reduce((a, fn) => fn(a), arg);

export const asyncPipe =
  (...functions) =>
  input =>
    functions.reduce((chain, func) => chain.then(func), Promise.resolve(input));

export const getNthIndex = (index, max) => (index >= max ? index % max : index);

export const pick = (obj, ...args) => ({
  ...args.reduce((res, key) => ({ ...res, [key]: obj[key] }), {}),
});

export const sortByKey = (list, key) =>
  list.sort((a, b) => {
    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
    }

    return a[key] - b[key];
  });

// Object.fromEntries but adds values with same key to array
export const stackEntries = entries =>
  entries.reduce(
    (object, [key, value]) => ({
      ...object,
      [key]: object[key] ? [...object[key], value] : [value],
    }),
    {}
  );

export const getTime = () => new Date().getTime();

export const isVerboseBuild =
  (process.env.npm_config_loglevel === 'debug' || process.env.npm_config_debug === 'true') &&
  isBuilding;

export const first = object => Object.values(object)[0];

export const distinct = array => Array.from(new Set(array));

export const getTagByDomain = domain => {
  switch (domain) {
    case 'denhamthejeanmaker.myshopify.com':
      return 'shopifyStaging';
    default:
      return 'shopifyProduction';
  }
};
