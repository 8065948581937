import { useState } from 'react';
import { encode } from 'shopify-gid';
import { useCustomer } from 'hooks';
import fetchJson from 'lib/utils/fetchJson';
import { PREFIX as accountPath } from 'components/pages/account/dashboard/slugs';
import { useStoreContext } from 'contexts';
import validatePassword from 'lib/validatePassword';

export const useResetPasswordCustomer = (id, resetToken) => {
  const { currency } = useStoreContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { mutateCustomer } = useCustomer({
    redirectTo: `/${accountPath}`,
    redirectIfFound: true,
  });

  const resetPassword = async (password, passwordConfirm) => {
    setError(null);

    const validationError = [
      validatePassword(password),
      password !== passwordConfirm && 'Passwords do not match.',
    ].filter(Boolean)[0];

    if (validationError) {
      setError(validationError);
    } else if (password && passwordConfirm) {
      setLoading(true);

      try {
        await mutateCustomer(
          fetchJson('/api/shopify/account/reset-password', {
            method: 'POST',
            headers: { currency },
            body: JSON.stringify({
              id: encode('Customer', id),
              input: {
                resetToken,
                password,
              },
            }),
          })
        );
        setLoading(false);
        setError(null);
      } catch (e) {
        setLoading(false);
        setError(e.message || true);
      }
    }
  };

  return [resetPassword, { loading, error }];
};

export default useResetPasswordCustomer;
