/* eslint-disable react/destructuring-assignment */
import Head from 'next/head';
import React from 'react';
import { SiteLink } from './shared';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Head>
            <title>Denham</title>
          </Head>
          <main className="flex min-h-screen flex-col items-center justify-center p-12 text-center">
            <div className="flex flex-col items-center gap-6">
              <h1 className="font-nimbus-sans-extd-d text-[20px] font-bold uppercase leading-[0.9] md:text-[35px]">
                Our bad... something went wrong
              </h1>
              <p className="text-[15px]">Please try reloading the page or come back later.</p>
              <button
                className="button w-fit"
                type="button"
                onClick={() => window.location.reload()}
              >
                Reload page
              </button>
              <SiteLink className="text-[12px] underline" href="/">
                Or return to the homepage
              </SiteLink>
            </div>
          </main>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
