import { useEffect } from 'react';
import Router from 'next/router';
import useSWRImmutable from 'swr/immutable';
import { getDataLayer, trackInWindow } from 'hooks/tracking/track';
import stripShopifyGid from 'lib/utils/stripShopifyGid';

const fetcher = (...args) => fetch(args).then(r => r.json());

const useCustomer = ({ redirectTo = false, redirectIfFound = false } = {}) => {
  const {
    data: customer,
    error,
    mutate: mutateCustomer,
  } = useSWRImmutable('/api/shopify/account/customer', fetcher);

  useEffect(() => {
    if (redirectTo && customer && Boolean(redirectIfFound) === Boolean(customer?.isLoggedIn))
      Router.push(redirectTo);

    const customerId = customer?.id ? stripShopifyGid(customer?.id) : null;
    if (customerId && !getDataLayer().find(e => e.userId === customerId))
      trackInWindow({
        userId: customerId,
      });
  }, [customer, redirectIfFound, redirectTo]);

  return { customer, mutateCustomer, isLoading: !customer && !error };
};

export default useCustomer;
