import { useEffect, useState } from 'react';
import { throttle } from 'underscore';

const THROTTLE_WAIT = 300;
export const SCROLL_CONTAINER_ID = 'scroll-container';

const getScrollContainer = document =>
  document.getElementById(SCROLL_CONTAINER_ID) || document.documentElement;

export const useDocumentScrollThrottled = callback => {
  const [, setScrollPosition] = useState(0);
  let previousScrollTop = 0;

  const onScroll = () => {
    const currentScrollTop = getScrollContainer(document).scrollTop;

    setScrollPosition(previous => {
      previousScrollTop = previous;
      return currentScrollTop;
    });

    callback({
      previousScrollTop,
      currentScrollTop,
      isScrollingDown: previousScrollTop < currentScrollTop,
    });
  };

  const onScrollThrothled = throttle(onScroll, THROTTLE_WAIT);

  useEffect(() => {
    window.addEventListener('scroll', onScrollThrothled, { passive: true });
    return () => window.removeEventListener('scroll', onScrollThrothled);
  }, []);
};

export default useDocumentScrollThrottled;
