export const IMAGE_QUERY_FIELDS = `
    sys {
      id
    }
    title
    description
    contentType
    fileName
    size
    url
    width
    height
`;

export const LINK_INLINE_FRAGMENTS = `
  ... on PageGeneral {
    __typename
    slug
  }
  ... on PageProduct {
    __typename
    slug
  }
  ... on PageCollection {
    __typename
    slug
  }
  ... on PageProductsListing {
    __typename
    slug
  }
`;

export const RICHTEXT_QUERY_FIELDS = `
json
links {
  entries {
    hyperlink {
      __typename
      sys { 
        id 
      }
      ... on PageGeneral {
        slug
      }
      ... on PageProduct {
        slug
      }
      ... on PageCollection {
        slug
      }
      ... on PageProductsListing {
        slug
      }
    }
  }
}
`;

export const LINK_QUERY_FIELDS = `
    name
    text
    link {
      ${LINK_INLINE_FRAGMENTS}
    }
    externalLink
    query
    emphasisColor
`;

export const RICH_TEXT_LINKS_FIELDS = `
  links {
    entries {
      hyperlink {
        sys {
          id
        }
        ${LINK_INLINE_FRAGMENTS}
      }
    }
  }
`;

export const NEWSLETTER_SIGN_UP_FIELDS = `
__typename
sys {
  id
}
title
description {
  json
}
buttonText
klaviyoListIdEu
klaviyoListIdUk
klaviyoListIdUs`;

export const pageSectionsCollectionFragment = supportedSections => {
  return `sectionsCollection(limit: 20) {
    items {
      ${supportedSections.map(s => `... on ${s} { sys { id } __typename }`)}
    }
  }`;
};

export const PAGE_JEANS_COLLECTION_QUERY = `
  __typename
  sys {
    id
  }
  title
  subtitle
  description
  tags
  slug
  availableFitsCollection(skip: 0, limit: 5) {
    items {
      caption
      media {
        ... on ComponentMedia {
          name
          image {
            url
            title
            description
          }
          vimeoUrl
        }
      }
    }
  }
  image {
    ... on ComponentMedia {
      image {
        url
        title
        description
      }
      vimeoUrl
    }
  }
  detailsCollection(skip: 0, limit: 4) {
    items {
        ... on ComponentMedia {
          image {
            url
            title
            description
          }
      }
    }
  }
  plp {
    ... on PageProductsListing {
      slug
      shopifyHandle
    }
  }
  measurementDescription {
    json
  }
  availableWashesCollection (limit: 8) {
    items {
      url
      title
    }
  }
`;
