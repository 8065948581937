import retry from 'async-retry';
import getStoreVariable from 'lib/storeLocales/getStoreVariable';

const isBrowser = typeof window !== 'undefined';

const retryFetch = (store, payload) =>
  retry(
    async bail => {
      const domain = getStoreVariable(store, 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_DOMAIN');
      const token = getStoreVariable(store, 'NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN');

      const response = await fetch(
        `https://${domain}/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql.json`,
        {
          method: 'POST',
          headers: {
            'X-Shopify-Storefront-Access-Token': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if ([403, 401].includes(response.status))
        bail(
          new Error(`Status [${response.status}] Response: ${JSON.stringify(response, null, 2)}`)
        );

      const json = await response.json();

      // Throw error for errors with 200 status
      if (json?.errors) {
        const extensionCode = json.errors[0].extensions?.code;

        if (['THROTTLED', 'TIMEOUT'].includes(extensionCode)) {
          throw new Error(`${extensionCode}
          
          ${JSON.stringify(payload, null, 2)}`);
        }

        return bail(
          new Error(`Status: [${response.status}] Error: ${JSON.stringify(json.errors, null, 2)}`)
        );
      }

      return json;
    },
    {
      minTimeout: 500,
      factor: 2,
      retries: isBrowser ? 0 : 2, // Only retry on server (SWR retries in browser)
      onRetry: ({ message }, attempt) =>
        console.log(`Retrying [${attempt}] error [${message.split('\n')[0]}]`),
    }
  );

export default retryFetch;
