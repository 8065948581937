import { useUI } from 'hooks';
import isDevelopment from 'lib/isDevelopment';
import Script from 'next/script';

const CookiePro = () => {
  const { setClosedCookieBanner, storeLocaleSelectorInitialized } = useUI();

  if (!storeLocaleSelectorInitialized) return null;

  return (
    <Script
      src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
      strategy="lazyOnload"
      data-document-language="true"
      type="text/javascript"
      charset="UTF-8"
      data-domain-script="3a46fb16-c4f4-4911-9c21-7ee233b879d8"
      onLoad={() => {
        setTimeout(() => {
          const { OneTrust } = window;

          const checkClosed = (bypass = false) => {
            if (bypass || !OneTrust || OneTrust.IsAlertBoxClosed()) {
              setClosedCookieBanner();
            }
          };

          checkClosed();

          if (OneTrust)
            OneTrust.OnConsentChanged(() => {
              checkClosed(isDevelopment);
            });
        }, 1000);
      }}
    />
  );
};

export default CookiePro;
