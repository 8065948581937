import { USP_BANNER_HEIGHT } from 'components/layout/uspBanner';
import { useIsDesktop } from 'hooks/utils/useIsDesktop';
import { useMemo, useReducer, createContext, useContext } from 'react';
import { HEADER_HEIGHT, HEADER_HEIGHT_DESKTOP } from '../constants';

const initialState = {
  displayCart: false,
  displayFilter: false,
  displayQuickShop: false,
  displayStoreLocaleSelector: false,
  displayNewsletterPopup: false,
  displayUspBanner: false,
  closedCookieBanner: false,
  storeLocaleSelectorInitialized: false,
};

const UIContext = createContext(initialState);

function uiReducer(state, action) {
  switch (action.type) {
    case 'OPEN_CART': {
      return {
        ...state,
        displayCart: true,
      };
    }
    case 'CLOSE_CART': {
      return {
        ...state,
        displayCart: false,
      };
    }
    case 'OPEN_FILTER': {
      return {
        ...state,
        displayFilter: true,
      };
    }
    case 'CLOSE_FILTER': {
      return {
        ...state,
        displayFilter: false,
      };
    }
    case 'OPEN_QUICKSHOP': {
      return {
        ...state,
        displayQuickShop: action.data,
      };
    }
    case 'CLOSE_QUICKSHOP': {
      return {
        ...state,
        displayQuickShop: false,
      };
    }
    case 'OPEN_STORELOCALESELECTOR': {
      return {
        ...state,
        displayStoreLocaleSelector: action.data,
      };
    }
    case 'CLOSE_STORELOCALESELECTOR': {
      return {
        ...state,
        displayStoreLocaleSelector: false,
        storeLocaleSelectorInitialized: true,
      };
    }
    case 'INITIALIZE_STORELOCALESELECTOR': {
      return {
        ...state,
        storeLocaleSelectorInitialized: true,
      };
    }
    case 'OPEN_NEWSLETTER_POPUP': {
      return {
        ...state,
        displayNewsletterPopup: true,
        displayCart: false,
        displayFilter: false,
      };
    }
    case 'CLOSE_NEWSLETTER_POPUP': {
      return {
        ...state,
        displayNewsletterPopup: false,
      };
    }
    case 'CLOSED_COOKIE_BANNER': {
      return {
        ...state,
        closedCookieBanner: true,
      };
    }
    default: {
      return console.log('no action');
    }
  }
}

export const useUI = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export const UIContextProvider = props => {
  const { headerTransparencyDesktop, children, state: propState = {}, isApp = false } = props;
  const [state, dispatch] = useReducer(uiReducer, { ...initialState, ...propState });
  const isDesktop = useIsDesktop();

  const openCart = () => dispatch({ type: 'OPEN_CART' });
  const closeCart = () => dispatch({ type: 'CLOSE_CART' });
  const toggleCart = () =>
    state.displayCart ? dispatch({ type: 'CLOSE_CART' }) : dispatch({ type: 'OPEN_CART' });

  const toggleFilter = () =>
    state.displayFilter ? dispatch({ type: 'CLOSE_FILTER' }) : dispatch({ type: 'OPEN_FILTER' });

  const toggleQuickShop = data =>
    state.displayQuickShop
      ? dispatch({ type: 'CLOSE_QUICKSHOP', data })
      : dispatch({ type: 'OPEN_QUICKSHOP', data });

  const openStoreLocaleSelector = data => dispatch({ type: 'OPEN_STORELOCALESELECTOR', data });
  const closeStoreLocaleSelector = () => dispatch({ type: 'CLOSE_STORELOCALESELECTOR' });
  const initializeStoreLocaleSelector = () => dispatch({ type: 'INITIALIZE_STORELOCALESELECTOR' });

  const openNewsletterPopup = () => dispatch({ type: 'OPEN_NEWSLETTER_POPUP' });
  const closeNewsletterPopup = () => dispatch({ type: 'CLOSE_NEWSLETTER_POPUP' });

  const setClosedCookieBanner = () => {
    dispatch({ type: 'CLOSED_COOKIE_BANNER' });
  };

  const toSumCalc = values =>
    `calc(${values.reduce((acc, value) => {
      return `${acc} + ${value}`;
    }, '0px')})`;

  const getShiftContent = () => {
    const includeUspBanner = state.displayUspBanner;

    const values = [HEADER_HEIGHT, ...(includeUspBanner ? [USP_BANNER_HEIGHT] : [])];

    return toSumCalc(values);
  };

  const getShiftContentDesktop = () => {
    const includeHeader = !headerTransparencyDesktop;
    const includeUspBanner = state.displayUspBanner;

    const values = [
      ...(includeHeader ? [HEADER_HEIGHT_DESKTOP] : []),
      ...(includeUspBanner ? [USP_BANNER_HEIGHT] : []),
    ];

    return toSumCalc(values);
  };

  const shiftContent = isApp ? '0px' : getShiftContent();
  const shiftContentDesktop = isApp ? '0px' : getShiftContentDesktop();

  const value = useMemo(
    () => ({
      ...state,
      isDesktop,
      openCart,
      closeCart,
      toggleCart,
      toggleFilter,
      toggleQuickShop,
      openStoreLocaleSelector,
      closeStoreLocaleSelector,
      shiftContent,
      shiftContentDesktop,
      openNewsletterPopup,
      closeNewsletterPopup,
      setClosedCookieBanner,
      initializeStoreLocaleSelector,
    }),
    [state, headerTransparencyDesktop, isDesktop]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <UIContext.Provider value={value} {...props}>
      {children}
    </UIContext.Provider>
  );
};
