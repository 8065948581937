import useSWR from 'swr';
import { findContentfulProductsInShopify } from 'lib/shopify/getMultipleProducts';
import { useStoreContext } from 'contexts';

export const useFetchContentfulProductsInShopify = (productItems, onSuccess) => {
  const { currency } = useStoreContext();

  const { data, error, isValidating } = useSWR(
    productItems,
    async (...items) => findContentfulProductsInShopify(items, currency),
    { ...(onSuccess ? { onSuccess } : {}), revalidateOnFocus: false }
  );

  if (error) console.error(error);

  return {
    data: data || [],
    isValidating,
  };
};

export default useFetchContentfulProductsInShopify;
