/* 
  Highstreet app banner meta tags 
    - Smart banner docs: https://t6622427.p.clickup-attachments.com/t6622427/33c3b4bf-ceb5-458c-848e-3d5002f27398/How%20to%20add%20app%20link%20data%20to%20your%20webpages%20_%20Highstreet%20Mobile.pdf?view=open
*/

import Head from 'next/head';

const HighstreetAppMeta = () => {
  return (
    <Head>
      <meta property="al:ios:app_name" content="Denham" />
      <meta property="al:ios:app_store_id" content="1359245727" />
    </Head>
  );
};

export default HighstreetAppMeta;
