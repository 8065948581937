export const BASE_PRODUCT = `
id
handle
title
tags
vendor
productType
metafield(namespace: "contentful", key: "product_data_plp") {
  value
  key
  type
}
metafield_style: metafield(namespace: "filters", key: "style") {
  value
  key
  type
}
metafield_articleNumber: metafield(namespace: "ois", key: "articleNumber") {
  value
  key
  type
}
metafield_genericColorName: metafield(namespace: "filters", key: "genericColorName") {
  value
  type
  key
}
metafield_gender: metafield(namespace: "filters", key: "gender") {
  value
  key
  type
}
metafield_genericFit: metafield(namespace: "filters", key: "genericFit") {
  value
  key
  type
}
`;

export const VARIANT_QUERY = `
  availableForSale
  id
  title
  image {
    transformedSrc(preferredContentType: WEBP, maxWidth: 190)
    width
    height
  }
  selectedOptions {
    name
    value
  }
  compareAtPriceV2 {
    amount
    currencyCode
  }
  priceV2 {
    amount
    currencyCode
  }
  compareAtPriceV2 {
    amount
    currencyCode
  }
  sku
  weight
  weightUnit
  product {
    ${BASE_PRODUCT}
  }
`;

export const LIST_PRODUCT = `
  ${BASE_PRODUCT}
  onlineStoreUrl
  compareAtPriceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  priceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  variants(first: 150) {
    edges {
      node {
        id
        title
        priceV2 {
          amount
          currencyCode
        }
        compareAtPriceV2 {
          amount
          currencyCode
        }
        quantityAvailable
        selectedOptions {
          name
          value
        }
      }
    }
  }
  options(first: 3) {
    id
    name
    values
  }
`;

export const PAGE_PRODUCT = `
${BASE_PRODUCT}
  onlineStoreUrl
  compareAtPriceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  priceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  variants(first: 150) {
    edges {
      node {
        title
        id
        sku
        quantityAvailable
        compareAtPriceV2 {
          amount
          currencyCode
        }
        priceV2 {
          amount
          currencyCode
        }
        compareAtPriceV2 {
          amount
          currencyCode
        }
        availableForSale
        selectedOptions {
          name
          value
        }
      }
    }
  }
  options(first: 3) {
    id
    name
    values
  }
`;

/*
  appliedGiftCards {
    lastCharacters
    amountUsedV2 {
      amount
    }
  }
*/

export const CART_QUERY = `
  id
  note
  cost {
    subtotalAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
    totalAmount {
      amount
      currencyCode
    }
  }
  lines(first: 250) {
    edges {
      node {
        id
        attributes {
          key
          value
        }
        quantity
        merchandise {
          ... on ProductVariant {
              ${VARIANT_QUERY}
          }
        }
      }
    }
  }
  checkoutUrl
  createdAt
`;
