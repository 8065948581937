import retryFetch from 'lib/utils/retryFetch';
import batchRequest from 'lib/utils/batchRequest';

const SPACE_ID = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.CONTENTFUL_CONTENT_DELIVERY_ACCESS_TOKEN;
const PREVIEW_TOKEN = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;
const ENVIRONMENT_ID = process.env.CONTENTFUL_ENVIRONMENT_ID;

// Returned if links are in draft
const unresolvableLinkCode = 'UNRESOLVABLE_LINK';

const filterNonCritical = error =>
  !error.extensions?.contentful?.code ||
  ![unresolvableLinkCode].includes(error.extensions.contentful.code);

export async function fetchContent(query, preview) {
  const res = await retryFetch(
    `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT_ID}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${preview ? PREVIEW_TOKEN : ACCESS_TOKEN}`,
      },
      body: JSON.stringify({ query }),
    }
  );
  const response = await res.json();

  if (response.errors && response.errors.filter(filterNonCritical).length) {
    throw new Error(`Rejected by Contentful! 
    
    ${JSON.stringify(response.errors, null, 2)}`);
  }

  return response.data;
}

export const fetchContentPayload = async (payload, preview = false) => {
  const res = await retryFetch(
    `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT_ID}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${preview ? PREVIEW_TOKEN : ACCESS_TOKEN}`,
      },
      body: JSON.stringify(payload),
    }
  );
  const response = await res.json();

  if (response.errors && response.errors.filter(filterNonCritical).length) {
    throw new Error(`Rejected by Contentful! 
    
    ${JSON.stringify(response.errors, null, 2)}`);
  }

  return response.data;
};

export const batchContentfulRequest = batchRequest(fetchContent);

export async function fetchAllLocales() {
  try {
    const res = await fetch(
      `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/${ENVIRONMENT_ID}/locales`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      }
    );
    const { items } = await res.json();

    const locales = items.map(locale => locale.code);

    return locales;
  } catch (error) {
    /* eslint-disable no-console */
    console.error('There was a problem retrieving locales');
    console.error(error);
    /* eslint-enable */
    return null;
  }
}
